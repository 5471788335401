<template>
    <!-- 图片模态框 -->
    <el-dialog
        :visible="dialogVisible"
        @close="handleCloseDialog"
        append-to-body>
        <!-- 操作按钮 -->
        <el-button 
            type="warning"
            class="dialog_download_btn"
            v-if="$route.path.indexOf('sample/detail')>=0">
            <i class="el-icon-download"></i>
            <span>下载</span>
        </el-button>
        <!-- 图片 -->
        <img width="100%" :src="require('@/assets/images/lensman/rBACE1Xf2GiDWRtEAAIyHllkybA426_600x.jpg')"
            v-if="dialogImageUrl && dialogImageUrl=='1'" alt="">
        <!-- <img width="100%" 
            :src="require('@/assets/images/field/'+dialogImageUrl)"
            v-else-if="dialogImageUrl && dialogImageUrl.indexOf('http')<0"> -->
        <img width="100%" 
            :src="dialogImageUrl"
            v-if="dialogImageUrl && dialogImageUrl.indexOf('http')>=0">
    </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: mapState({
        // 模态框控制器
        dialogVisible: state => state.dialogVisible,
        // 模态框图片
        dialogImageUrl: state => state.dialogImageUrl,
    }),
    methods: {
        ...mapMutations([
            // 模态框控制器
            "changeDialogVisible",
            // 图片展示模态框地址写入
            "writeDialogImgUrl",
        ]),
        // 关闭模态框
        handleCloseDialog(){
            this.changeDialogVisible(false);
            this.writeDialogImgUrl("");
        },
    },
}
</script>

<style scoped>
.dialog_download_btn{
    margin-bottom: 20px;
}
</style>
